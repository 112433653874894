body {
  font-size: 14px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: var(--poppins);
  font-weight: 300;
}

div[role="button"] {
  outline: none;
}

.dark {
  background: var(--black1);
  color: var(--white);
}

body.disable-scroll {
  overflow: hidden;
}

html {
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  line-height: unset;
  font-size: 14px;
}

h3 {
  font-weight: 300;
}

a {
  line-height: unset;
  font-weight: 300;
}

// * {
//   outline: 1px solid red;
// }
